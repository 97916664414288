import styled from "@emotion/styled"
import { colors, fonts, transitions } from "../theme"

const sliceWidth = "200px"

export const Hero = styled.section`
  min-height: 725px;
  background-color: ${colors.army};
  position: relative;
  overflow: hidden;

  @media (min-width: 1024px) {
    height: 100vh;
  }
`

export const HeroBG = styled.div`
  @media (max-width: 1023px) {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${colors.black};
    opacity: 0.67;
    z-index: 1;
  }
`

export const HeroLeft = styled.div`
  height: 100%;
  z-index: 10;
  position: absolute;
  left: 7.5%;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media (min-width: 1024px) {
    max-width: 45%;
  }
`

export const HeroLeftTitle = styled.h1`
  color: ${colors.white};
  margin: 0 0 1rem;
  ${fonts.play};
  font-size: 4rem;

  @media (min-width: 1024px) {
    font-size: 5vw;
  }
`

export const HeroLeftSubtitle = styled.p`
  color: ${colors.white};
  margin: 0;
  ${fonts.play};
  font-size: 1.5rem;
  margin: 0 0 4rem;
`

export const HeroLeftScroll = styled.button`
  color: ${colors.white};
  text-transform: uppercase;
  text-decoration: none;
  ${fonts.mont.bold};
  display: flex;
  flex-direction: row;
  align-items: center;
  letter-spacing: 0.25rem;
  font-size: 0.875rem;
  border: none;
  background: transparent;
  margin: 0;
  padding: 0;
  ${transitions.default};

  @media (min-width: 1024px) {
    color: ${colors.olive};
  }

  &::before {
    content: "";
    width: 8rem;
    height: 2px;
    background-color: ${colors.white};
    display: inline-block;
    margin-right: 2rem;
    transform: translateY(50%);
    ${transitions.default};

    @media (min-width: 1024px) {
      background-color: ${colors.olive};
    }
  }

  &:hover {
    cursor: pointer;
    color: ${colors.white};

    &::before {
      background-color: ${colors.white};
    }
  }
`

export const HeroRight = styled.div`
  height: 90%;
  width: 95%;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-45%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (min-width: 1024px) {
    width: 60%;
    right: 1rem;
    left: unset;
    margin: 0;
  }

  .gatsby-image-wrapper {
    margin: 0 0.5rem;

    img {
      filter: saturate(0.67);
    }

    &:nth-of-type(1) {
      height: 55%;
      width: ${sliceWidth};
    }

    &:nth-of-type(2) {
      height: 75%;
      width: ${sliceWidth};
      margin-top: 5%;
    }

    &:nth-of-type(3) {
      height: 80%;
      width: ${sliceWidth};
      margin-bottom: 13.5%;
    }

    &:nth-of-type(4) {
      height: 80%;
      width: ${sliceWidth};
    }

    &:nth-of-type(5) {
      height: 55%;
      width: ${sliceWidth};
      margin-bottom: 7%;
    }
  }
`
