import React, { useEffect } from "react"
import { StaticQuery, graphql } from "gatsby"
import {
  FContent,
  FContentTitle,
  FContentTopLeft,
  FContentTopRight,
  FContentBottomLeft,
  FContentBottomRight,
  Modal,
  ModalContent,
  ModalBG,
  ModalClose,
} from "./front-content-styles"
import PortableText from "@sanity/block-content-to-react"
import anime from "animejs"

function scroll_footer() {
  const form = document.querySelector(".footer__form")
  form.scrollIntoView({
    block: "start",
    behavior: "smooth",
  })
}

function open_modal() {
  anime({
    targets: document.querySelector(".modal"),
    translateY: "0",
    duration: 400,
    easing: "easeInOutCubic",
  })
}

function close_modal() {
  const wrapper = document.querySelector(".modal__content")
  const content = wrapper.innerHTML

  anime({
    targets: document.querySelector(".modal"),
    translateY: "-100%",
    duration: 400,
    easing: "easeInOutCubic",
    complete: () => {
      wrapper.innerHTML = ""
      wrapper.innerHTML = content
    },
  })
}

export default () => {
  useEffect(() => {
    anime({
      targets: document.querySelector(".modal"),
      translateY: "-100%",
      duration: 1,
    })
  })

  return (
    <StaticQuery
      query={graphql`
        {
          allSanityHome {
            edges {
              node {
                _rawHomeContent(resolveReferences: { maxDepth: 10 })
              }
            }
          }
        }
      `}
      render={data => {
        const content = data.allSanityHome.edges[0].node._rawHomeContent

        return (
          <>
            <FContent className="front__content">
              <FContentTopLeft>
                <FContentTitle className="anim-in-up">
                  {content.leftTitle}
                </FContentTitle>
                <PortableText
                  className="anim-in-up"
                  blocks={content.leftContent}
                  serializers={{
                    types: {
                      ptImage: ({ node }) => (
                        <img src={node.image.asset.url} alt={node.alt} />
                      ),
                    },
                  }}
                />
              </FContentTopLeft>
              <FContentTopRight>
                <img
                  src={content.leftImage.image.asset.url}
                  alt={content.leftImage.alt}
                />
                <button onClick={scroll_footer}>request an appointment</button>
              </FContentTopRight>
              <FContentBottomLeft>
                <img
                  src={content.rightImage.image.asset.url}
                  alt={content.rightImage.alt}
                />
                <button onClick={open_modal}>play video</button>
              </FContentBottomLeft>
              <FContentBottomRight>
                <FContentTitle className="anim-in-down">
                  {content.rightTitle}
                </FContentTitle>
                <PortableText
                  className="anim-in-down"
                  blocks={content.rightContent}
                  serializers={{
                    types: {
                      ptImage: ({ node }) => (
                        <img src={node.image.asset.url} alt={node.alt} />
                      ),
                    },
                  }}
                />
              </FContentBottomRight>
            </FContent>
            <Modal className="modal">
              <ModalBG />
              <ModalClose onClick={close_modal}>&times;</ModalClose>
              <ModalContent
                className="modal__content"
                dangerouslySetInnerHTML={{ __html: content.modalVideo }}
              />
            </Modal>
          </>
        )
      }}
    />
  )
}
