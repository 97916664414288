import React from "react"
import { StaticQuery, graphql } from "gatsby"
import {
  ReviewWrapper,
  ReviewStars,
  ReviewSingle,
  ReviewLink,
  ReviewImage,
} from "./reviews-styles"
import PortableText from "@sanity/block-content-to-react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar } from "@fortawesome/free-solid-svg-icons"

export default () => (
  <StaticQuery
    query={graphql`
      {
        allSanityReview {
          edges {
            node {
              _rawReviews
            }
          }
          totalCount
        }
        rslices: file(relativePath: { eq: "img-3.png" }) {
          childImageSharp {
            fixed(width: 250, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        allSanityContact {
          edges {
            node {
              fbLink
            }
          }
        }
      }
    `}
    render={data => {
      const total = data.allSanityReview.totalCount
      const random = Math.floor(Math.random() * total)
      const single = data.allSanityReview.edges[random].node
      const links = data.allSanityContact.edges[0].node

      return (
        <ReviewWrapper>
          <ReviewStars className="anim-in-up">
            <FontAwesomeIcon icon={faStar} size="2x" />
            <FontAwesomeIcon icon={faStar} size="2x" />
            <FontAwesomeIcon icon={faStar} size="2x" />
            <FontAwesomeIcon icon={faStar} size="2x" />
            <FontAwesomeIcon icon={faStar} size="2x" />
          </ReviewStars>
          <ReviewSingle className="anim-in-up">
            <PortableText
              blocks={single._rawReviews}
              serializers={{
                types: {
                  ptImage: ({ node }) => (
                    <img src={node.image.asset.url} alt={node.alt} />
                  ),
                },
              }}
            />
          </ReviewSingle>
          <ReviewLink
            className="anim-in-up"
            href={links.fbLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            what our clients are saying
          </ReviewLink>
          <ReviewImage fixed={data.rslices.childImageSharp.fixed} />
        </ReviewWrapper>
      )
    }}
  />
)
