import React from "react"
import { StaticQuery, graphql } from "gatsby"
import {
  GalleryWrapper,
  GalleryItem,
  GalleryLink,
  SocialItem,
  SocialList,
} from "./gallery-styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookSquare,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons"

export default () => (
  <StaticQuery
    query={graphql`
      {
        allSanityGallery {
          edges {
            node {
              slides {
                image {
                  asset {
                    fluid(maxWidth: 960) {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
              }
            }
          }
        }
        allSanityContact {
          edges {
            node {
              fbLink
              instaLink
            }
          }
        }
      }
    `}
    render={data => {
      const links = data.allSanityContact.edges[0].node
      const images = data.allSanityGallery.edges[0].node.slides

      return (
        <GalleryWrapper>
          <GalleryItem
            className="anim-in-up"
            fluid={images[0].image.asset.fluid}
          />
          <GalleryItem
            className="anim-in-up"
            fluid={images[1].image.asset.fluid}
          />
          <GalleryItem
            className="anim-in-up"
            fluid={images[2].image.asset.fluid}
          />
          <GalleryLink to="/gallery/">View our Work</GalleryLink>
          <SocialList>
            <SocialItem>
              <a
                href={links.fbLink}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Facebook Link"
              >
                <FontAwesomeIcon icon={faFacebookSquare} size="3x" />
              </a>
            </SocialItem>
            <SocialItem>
              <a
                href={links.instaLink}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Instagram Link"
              >
                <FontAwesomeIcon icon={faInstagram} size="3x" />
              </a>
            </SocialItem>
          </SocialList>
        </GalleryWrapper>
      )
    }}
  />
)
