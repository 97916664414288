import styled from "@emotion/styled"
import { colors, fonts, transitions } from "../theme"

export const StylistWrapper = styled.section`
  background-color: ${colors.gray};
  position: relative;

  &::before {
    @media (min-width: 1024px) {
      width: 25%;
      height: 2rem;
      background-color: ${colors.darkolive};
      display: block;
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      transform: translateY(-100%);
    }
  }
`

export const StylistSlider = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;

  @media (min-width: 1024px) {
    min-height: 550px;
  }
`

export const StylistSliderSlide = styled.li`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 33vw 67vw;
    grid-gap: 0;
  }
`

export const StylistSliderLeft = styled.div`
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const StylistSliderRight = styled.div`
  padding: 2rem;
  line-height: 1.75;

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 6rem 8rem;
  }
`

export const StylistTitle = styled.h2`
  margin: 0 0 1rem 0;
  ${fonts.play};
  font-size: 2.5rem;
  line-height: 1.25;
  color: ${colors.darkolive};

  @media (min-width: 1024px) {
    max-width: 67%;
    margin-left: -3rem;
  }
`

export const StylistControls = styled.div`
  z-index: 10;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;

  @media (max-width: 767px) {
    max-width: 450px;
    margin: 0 auto;
    padding: 2rem 1rem;
  }

  @media (min-width: 1024px) {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
`

export const StylistControlButton = styled.button`
  border: none;
  background-color: transparent;
  font-size: 1.25rem;
  ${fonts.play};
  display: flex;
  flex-direction: row;
  align-items: center;
  ${transitions.default};
  outline: none;

  &:disabled {
    color: ${colors.black};
  }

  &:not(.active) {
    color: #c3c3c3;
  }

  &:hover {
    cursor: pointer;
    color: ${colors.black};
  }

  &:not(:last-of-type) {
    @media (min-width: 1024px) {
      &::after {
        content: "/";
        display: block;
        width: 100%;
        height: 100%;
        margin-left: 1rem;
        transition: all 0;
      }
    }
  }
`
