import React, { useEffect } from "react"
import { StaticQuery, graphql } from "gatsby"
import {
  Hero,
  HeroBG,
  HeroLeft,
  HeroLeftTitle,
  HeroLeftSubtitle,
  HeroLeftScroll,
  HeroRight,
} from "./front-hero-styles"
import Img from "gatsby-image"
import anime from "animejs"

export default () => {
  useEffect(() => {
    const images = document.querySelectorAll(".slice")
    const text = document.querySelectorAll(".hero-text")

    images.forEach(img => {
      anime({
        targets: img,
        translateY: "-100vh",
        opacity: 0,
        duration: 1,
      })
    })

    text.forEach(line => {
      anime({
        targets: line,
        opacity: 0,
        duration: 1,
      })
    })

    setTimeout(() => {
      let delay = 0

      images.forEach(el => {
        anime({
          targets: el,
          translateY: "0",
          opacity: 1,
          duration: 600,
          delay: delay,
          easing: "easeInOutCubic",
        })
        delay = delay + 300
      })
    }, 100)

    setTimeout(() => {
      let delay2 = 0
      text.forEach(el => {
        anime({
          targets: el,
          opacity: 1,
          duration: 400,
          delay: delay2,
          easing: "easeInOutCubic",
        })
        delay2 = delay2 + 100
      })
    }, 1800)
  })

  function learn_more() {
    const form = document.querySelector(".front__content")
    form.scrollIntoView({
      block: "start",
      behavior: "smooth",
    })
  }

  return (
    <StaticQuery
      query={graphql`
        {
          slice1: file(relativePath: { eq: "hero-1.png" }) {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          slice2: file(relativePath: { eq: "hero-2.png" }) {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          slice3: file(relativePath: { eq: "hero-3.png" }) {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          slice4: file(relativePath: { eq: "hero-4.png" }) {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          slice5: file(relativePath: { eq: "hero-5.png" }) {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          allSanityHome {
            edges {
              node {
                hero {
                  bannerTitle
                  bannerSubtitle
                }
              }
            }
          }
        }
      `}
      render={data => {
        const hero = data.allSanityHome.edges[0].node.hero

        return (
          <Hero>
            <HeroBG />
            <HeroLeft>
              <HeroLeftTitle className="hero-text">
                {hero.bannerTitle}
              </HeroLeftTitle>
              <HeroLeftSubtitle className="hero-text">
                {hero.bannerSubtitle}
              </HeroLeftSubtitle>
              <HeroLeftScroll className="hero-text" onClick={learn_more}>
                learn more
              </HeroLeftScroll>
            </HeroLeft>
            <HeroRight>
              <Img
                fluid={data.slice1.childImageSharp.fluid}
                alt="Interior of Artemis Salon, first slice"
                className="slice"
              />
              <Img
                fluid={data.slice2.childImageSharp.fluid}
                alt="Interior of Artemis Salon, second slice"
                className="slice"
              />
              <Img
                fluid={data.slice3.childImageSharp.fluid}
                alt="Interior of Artemis Salon, third slice"
                className="slice"
              />
              <Img
                fluid={data.slice4.childImageSharp.fluid}
                alt="Interior of Artemis Salon, fourth slice"
                className="slice"
              />
              <Img
                fluid={data.slice5.childImageSharp.fluid}
                alt="Interior of Artemis Salon, fifth slice"
                className="slice"
              />
            </HeroRight>
          </Hero>
        )
      }}
    />
  )
}
