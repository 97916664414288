import styled from "@emotion/styled"
import { colors, fonts, transitions } from "../theme"
import Img from "gatsby-image"

export const ReviewWrapper = styled.section`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  background-color: ${colors.white};

  @media (min-width: 768px) {
    padding: 3rem 4rem;
  }

  @media (min-width: 1024px) {
    padding: 6rem 8rem;
  }
`

export const ReviewStars = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    color: ${colors.olive};
    margin-right: 0.25rem;
  }
`

export const ReviewSingle = styled.div`
  list-style: none;
  line-height: 1.5;
  ${fonts.play};
  font-size: 1.75rem;
  color: ${colors.darkolive};

  @media (min-width: 1024px) {
    max-width: 60%;
    margin-right: auto;
    margin-left: 0;
  }

  p {
    margin-top: 2rem;
  }
`

export const ReviewLink = styled.a`
  ${fonts.mont.bold};
  font-size: 0.875rem;
  text-transform: uppercase;
  text-decoration: none;
  color: ${colors.darkolive};
  letter-spacing: 0.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  padding: 1rem;
  position: relative;
  right: 1rem;
  ${transitions.default};

  &::before {
    display: none;
    content: "";
    width: 8rem;
    height: 2px;
    background-color: ${colors.olive};
    margin-right: 2rem;
    transform: translateY(50%);
    ${transitions.default};

    @media (min-width: 768px) {
      display: inline-block;
    }
  }

  &:hover {
    background-color: ${colors.darkolive};
    color: ${colors.white};

    &::before {
      background-color: ${colors.white};
    }
  }
`

export const ReviewImage = styled(Img)`
  position: absolute !important;
  right: 12.5%;
  top: 50%;
  transform: translateY(-75%);

  @media (max-width: 1023px) {
    display: none !important;
  }
`
