import React from "react"
import SEO from "../components/seo"

import { Global, css } from "@emotion/core"
import FrontHero from "../components/hero/front-hero"
import FrontContent from "../components/content/front-content"
import Reviews from "../components/reviews/reviews"
import Stylists from "../components/stylists/stylists"
import Gallery from "../components/gallery/gallery"

const HomePage = () => {
  return (
    <>
      <Global
        styles={css`
          .logo {
            img {
              filter: invert(100) saturate(0) brightness(100) contrast(100);
            }
          }
        `}
      />
      <SEO title="Home" />
      <FrontHero />
      <FrontContent />
      <Reviews />
      <Stylists />
      <Gallery />
    </>
  )
}

export default HomePage
