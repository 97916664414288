import React, { useEffect, useState } from "react"
import { StaticQuery, graphql } from "gatsby"
import { useWindowSize } from "react-use"
import {
  StylistWrapper,
  StylistSlider,
  StylistSliderSlide,
  StylistSliderLeft,
  StylistSliderRight,
  StylistTitle,
  StylistControls,
  StylistControlButton,
} from "./stylists-styles"
import PortableText from "@sanity/block-content-to-react"
import anime from "animejs"

export default () => {
  const { width } = useWindowSize()
  const [current, setCurrent] = useState(0)

  useEffect(() => {
    const slides = document.querySelectorAll(`[data-slide-index]:not(.current)`)

    slides.forEach(slide => {
      anime({
        targets: slide,
        translateX: "100%",
        duration: 1,
      })
    })

    const actButt = document.querySelector(".active")
    if (actButt) {
      actButt.disabled = true
    }

    setTimeout(() => {
      resize_slider()
      window.addEventListener("resize", resize_slider)
    }, 1000)
  })

  function resize_slider() {
    const right = document.querySelector(".stylist__right")
    const left = document.querySelector(".stylist__left")
    const slider = document.querySelector(".stylist")

    if (slider) {
      if (width >= 1024) {
        slider.style.height = right.clientHeight - 4 + "px"
      } else {
        const both = right.clientHeight + left.clientHeight
        slider.style.height = both - 4 + "px"
      }
    }
  }

  function change_slide(index) {
    const max = document.querySelector(".stylist").childElementCount
    if (index < 0) {
      index = max - 1
    } else if (index > max) {
      index = 0
    }
    setCurrent(index)
    const current = document.querySelector(".current")
    const next = document.querySelector(`[data-slide-index="${index}"]`)

    if (width >= 1024) {
      const actButt = document.querySelector(".active")
      const button = document
        .querySelector(".controls")
        .querySelector(`button:nth-of-type(${index + 1})`)
      actButt.classList.remove("active")
      button.classList.add("active")
      actButt.disabled = false
      button.disabled = true
    }

    anime({
      targets: current,
      translateX: "-100%",
      duration: 400,
      easing: "easeInOutCubic",
      complete: () => {
        current.classList.remove("current")
        next.classList.add("current")

        anime({
          targets: current,
          translateX: "100%",
          duration: 1,
        })
      },
    })

    setTimeout(() => {
      anime({
        targets: next,
        translateX: "0",
        duration: 400,
        easing: "easeInOutCubic",
      })
    }, 200)
  }

  return (
    <StaticQuery
      query={graphql`
        {
          allSanityStylist(sort: { order: DESC, fields: _updatedAt }) {
            edges {
              node {
                image {
                  alt
                  image {
                    asset {
                      url
                    }
                  }
                }
                _rawBio
                name
              }
            }
            totalCount
          }
        }
      `}
      render={data => {
        const stylists = data.allSanityStylist.edges
        const controls = []

        if (width >= 1024) {
          for (let i = 0; i < data.allSanityStylist.totalCount; i++) {
            let className = ""
            if (i === 0) {
              className = "active"
            }
            controls.push(
              <StylistControlButton
                key={i}
                className={className}
                onClick={() => change_slide(i)}
              >
                0{i + 1}
              </StylistControlButton>
            )
          }
        } else if (width < 1024) {
          controls.push(
            <StylistControlButton
              key={Math.random()}
              onClick={() => change_slide(current - 1)}
            >
              Prev
            </StylistControlButton>
          )
          controls.push(
            <StylistControlButton
              key={Math.random()}
              onClick={() => change_slide(current + 1)}
            >
              Next
            </StylistControlButton>
          )
        }

        return (
          <StylistWrapper>
            <StylistControls className="controls">{controls}</StylistControls>
            <StylistSlider className="stylist">
              {stylists.map((single, index) => {
                let cls = ""
                if (index === 0) {
                  cls = "current"
                }
                return (
                  <StylistSliderSlide
                    key={index}
                    className={cls}
                    data-slide-index={index}
                  >
                    <StylistSliderLeft className="stylist__left">
                      <img
                        src={single.node.image.image.asset.url}
                        alt={single.node.image.alt}
                      />
                    </StylistSliderLeft>
                    <StylistSliderRight className="stylist__right">
                      <StylistTitle className="anim-in-down">
                        Meet {single.node.name}
                      </StylistTitle>
                      <PortableText
                        className="anim-in-down"
                        key={index}
                        blocks={single.node._rawBio}
                        serializers={{
                          types: {
                            ptImage: ({ node }) => (
                              <img src={node.image.asset.url} alt={node.alt} />
                            ),
                          },
                        }}
                      />
                    </StylistSliderRight>
                  </StylistSliderSlide>
                )
              })}
            </StylistSlider>
          </StylistWrapper>
        )
      }}
    />
  )
}
