import styled from "@emotion/styled"
import { colors, fonts, transitions } from "../theme"

export const FContent = styled.section`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 0;

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(5, 1fr);
    max-height: 1440px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
  }
`

export const FContentTitle = styled.p`
  margin: 0;
  ${fonts.play};
  font-size: 2.5rem;
  line-height: 1.125;
  margin-bottom: 2rem;

  @media (min-width: 1280px) {
    font-size: 3.5rem;
    max-width: 67%;
    margin-left: -3rem;
  }
`

const blockStyles = {
  padding: "1rem 1.5rem",
  lineHeight: 2,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",

  "@media (min-width: 768px)": {
    padding: "2rem 3rem",
  },

  "@media (min-width: 1024px)": {
    padding: "4rem 6rem",
  },
}

const height = "750px"

export const FContentTopLeft = styled.div`
  grid-area: 1;
  background-color: ${colors.darkolive};
  color: ${colors.white};
  ${blockStyles};

  @media (min-width: 1024px) {
    grid-area: 1 / 1 / 4 / 3;
  }
`
export const FContentTopRight = styled.div`
  grid-area: 2;
  position: relative;
  z-index: 2;

  @media (min-width: 1024px) {
    max-height: ${height};
    grid-area: 1 / 3 / 3 / 5;
  }

  button {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    max-width: 67%;
    transform: translateY(50%);
    border: none;
    background-color: ${colors.black};
    padding: 1.5rem 2rem;
    color: ${colors.white};
    ${fonts.mont.bold};
    font-size: 0.875rem;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    letter-spacing: 3px;
    ${transitions.default};

    &:hover {
      cursor: pointer;
      background-color: ${colors.white};
      color: ${colors.darkolive};
    }
  }
`
export const FContentBottomLeft = styled.div`
  grid-area: 4;
  position: relative;

  @media (min-width: 1024px) {
    max-height: ${height};
    grid-area: 4 / 1 / 6 / 3;
  }

  button {
    position: absolute;
    right: 0rem;
    bottom: 0rem;
    background-color: transparent;
    color: ${colors.white};
    ${fonts.mont.bold};
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem 2rem;
    ${transitions.default};
    letter-spacing: 0.25rem;
    font-size: 0.875rem;

    &::before {
      display: inline-block;
      content: "";
      width: 8rem;
      height: 1px;
      background-color: ${colors.white};
      margin-right: 2rem;
      transform: translateY(50%);
      ${transitions.default};
    }

    &:hover {
      cursor: pointer;
      /* background-color: ${colors.white};
      color: ${colors.black};

      &::before {
        background-color: ${colors.black};
      } */
    }
  }
`
export const FContentBottomRight = styled.div`
  grid-area: 3;
  background-color: ${colors.gray};
  ${blockStyles};

  @media (min-width: 1024px) {
    grid-area: 3 / 3 / 6 / 5;
  }

  ${FContentTitle} {
    color: ${colors.darkolive};
  }

  h2 {
    color: ${colors.darkolive};
    ${fonts.mont.bold};
    font-size: 1.5rem;
    text-transform: uppercase;

    @media (min-width: 768px) {
      font-size: 2rem;
    }
  }
`

export const Modal = styled.section`
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 50;
`

export const ModalClose = styled.button`
  position: absolute;
  right: 1rem;
  top: 1.5rem;
  color: ${colors.olive};
  font-size: 3rem;
  background-color: transparent;
  border: none;
  ${transitions.default};
  line-height: 1ch;
  padding: 1rem;

  &:hover {
    cursor: pointer;
    background-color: ${colors.olive};
    color: ${colors.white};
  }
`

export const ModalBG = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: ${colors.black};
  opacity: 0.5;
`
export const ModalContent = styled.div`
  z-index: 2;
  padding: 1rem;

  iframe {
    max-width: 100%;
  }
`
