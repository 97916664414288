import styled from "@emotion/styled"
import { colors, fonts, transitions } from "../theme"
import Img from "gatsby-image"
import { Link } from "gatsby"

export const GalleryWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 550px;
  position: relative;
  z-index: 2;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`

export const GalleryItem = styled(Img)`
  height: 550px;
  width: 100%;

  @media (min-width: 768px) {
    width: calc(100% / 3);
  }
`

export const GalleryLink = styled(Link)`
  max-width: 300px;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.black};
  color: ${colors.white};
  ${fonts.mont.bold};
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  letter-spacing: 3px;
  border: none;
  padding: 1.5rem 3rem;
  transform: translateY(50%);
  ${transitions.default};

  &:hover {
    color: ${colors.darkolive};
    background-color: ${colors.white};
  }
`

export const SocialList = styled.ul`
  position: absolute;
  right: 1rem;
  top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
`

export const SocialItem = styled.li`
  margin: 0 1rem;

  svg {
    color: ${colors.white};
    ${transitions.default};

    &:hover {
      color: ${colors.olive};
    }
  }
`
